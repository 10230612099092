// ------home page
import { Component, Vue } from "vue-property-decorator";
import ToFooter from "@/components/footer/footer.vue";
import Header from "@/components/header/header.vue";

@Component({
  components: {
      ToFooter,
      Header,
  }
})
export default class Companyprofile extends Vue {
    private toptype:number=2;

    // 左侧菜单跳转
    toPage(index:number) {
        let owner=this;
        owner.toptype=index;
        if(index===1){
            this.$router.push({
                path: "/home",
                query: {}
            });
        }else if(index===2){
            this.$router.push({
                path: "/companyprofile",
                query: {}
            });
        }else if(index===3){
            this.$router.push({
                path: "/product",
                query: {}
            });
        }else if(index===4){
            this.$router.push({
                path: "/news",
                query: {}
            });
        }else if(index===5){
            this.$router.push({
                path: "/mine",
                query: {}
            });
        }
    }

    private created() {
        window.scrollTo(0, 0);
    }
}
